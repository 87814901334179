export const routes = [
    {
        path: '/expense_types',
        name: 'expense_types.browse',
        component: () => import(/* webpackChunkName: "ExpenseTypesBrowse" */ '@/views/app/ExpenseTypesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/expense_types/create',
        name: 'expense_types.create',
        component: () => import(/* webpackChunkName: "ExpenseTypesActions" */ '@/views/app/ExpenseTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/expense_types/:id/edit',
        name: 'expense_types.edit',
        component: () => import(/* webpackChunkName: "ExpenseTypesActions" */ '@/views/app/ExpenseTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/expense_types/:id/delete',
        name: 'expense_types.delete',
        component: () => import(/* webpackChunkName: "ExpenseTypesActions" */ '@/views/app/ExpenseTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]